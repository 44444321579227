<template>
  <div class="merch">
    <!-- 搜索列 -->
    <el-form
      ref="queryParams"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleCurrentChange"
    >
      <el-form-item prop="fileType">
        <el-select v-model="queryParams.fileType" placeholder="类型" clearable>
          <el-option label="新增商品" :value="0"></el-option>
          <el-option label="更新图片" :value="2"></el-option>
          <el-option label="更新说明书" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          clearable
          v-model="queryParams.auditStatus"
          placeholder="审核状态"
        >
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="已通过" :value="2"></el-option>
          <el-option label="部分通过" :value="4"></el-option>
          <el-option label="已拒绝" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryParams.auditdateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="审核开始日期"
          end-placeholder="审核结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryParams.dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="上传开始日期"
          end-placeholder="上传结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryParams.businessName"
          placeholder="请输入供应商名称/编号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleCurrentChange"
          style="width: 80px"
          >查 询</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div class="merch-table scorr-roll">
      <el-table
        :data="searchListData"
        v-loading="tableLoading"
        border
        :header-cell-style="{ background: '#F2F3F5' }"
      >
        <el-table-column prop="id" label="编号" align="center" />
        <el-table-column prop="businessName" label="供应商" align="center" />
        <el-table-column prop="createTime" label="提交时间" align="center" />
        <el-table-column prop="operatorName" label="提交人" align="center" />
        <el-table-column label="文件类型" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.fileType | fileState }}</div>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" align="center">
          <template slot-scope="{ row }">
            <div :style="auditStyle(row.auditStatus)">
              {{ row.auditStatusStr }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="审核人" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.auditor || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="auditTime" label="审核时间" align="center" />
        <el-table-column label="审核结果" align="center">
          <template slot-scope="{ row }">
            <div v-if="[2, 3, 4].includes(row.auditStatus)">
              共<span style="color: #000000">{{ row.totalNum }}</span
              >条，成功<span style="color: #06b7ae">{{
                row.auditSuccessNum
              }}</span
              >条， 失败<span style="color: red">{{ row.auditFailNum }}</span
              >条
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="{ row }">
            <span
              v-if="row.auditStatus == 1"
              class="btsed"
              @click="ReviewImmediate(row)"
              >立即审核</span
            >
            <span v-else class="btsed" @click="detailed(row)">明细</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="merch-page">
      <el-pagination
        background
        @current-change="searchBusinessList"
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="10"
        :current-page="current_page"
      >
      </el-pagination>
    </div>
    <el-drawer
      title="审核"
      :visible.sync="auditDrawer"
      direction="rtl"
      :before-close="handleClose"
      size="750px"
    >
      <div style="padding: 0 20px">
        <div class="gysFileInfo">
          <div class="gys-row">
            <div>供应商名称</div>
            <div>{{ gysInfo.businessName }}</div>
          </div>
          <div class="gys-row">
            <div>文件编号</div>
            <div>{{ gysInfo.id }}</div>
          </div>
          <div class="gys-row">
            <div>商品原文件</div>
            <div
              class="text_btn"
              @click="handleDownFile(gysInfo.importFileUrl)"
            >
              下载商品原文件
            </div>
          </div>
          <div class="gys-row">
            <div>提交人</div>
            <div>{{ gysInfo.operatorName }}</div>
          </div>
          <div class="gys-row">
            <div>提交时间</div>
            <div>{{ gysInfo.createTime }}</div>
          </div>
        </div>
        <div class="xuxian"></div>
        <el-form
          ref="auditForm"
          :rules="rules"
          :model="auditForm"
          label-width="170px"
        >
          <el-form-item label="审核结果" prop="auditStatus" :required="true">
            <el-radio-group
              v-model="auditForm.auditStatus"
              style="margin-left: 100px"
            >
              <el-radio :label="1">审核通过</el-radio>
              <el-radio :label="2">审核驳回</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="是否修改过上传文件"
            prop="isUpdateFile"
            :required="true"
            v-if="auditForm.auditStatus * 1 == 1"
          >
            <el-radio-group
              v-model="auditForm.isUpdateFile"
              style="margin-left: 100px"
            >
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
            <span
              v-if="auditForm.isUpdateFile * 1 == 2"
              style="color: #06b7ae; margin-left: 20px"
              >通过后按原商品文件商品导入</span
            >
          </el-form-item>
          <el-form-item
            label="上传审核结果文件"
            prop="updateFile"
            :required="true"
            v-if="
              auditForm.auditStatus * 1 == 2 ||
              (auditForm.auditStatus * 1 == 1 &&
                auditForm.isUpdateFile * 1 == 1)
            "
          >
            <div style="display: flex">
              <el-upload
                :action="Actions"
                :headers="token"
                :before-upload="handleBeforeUpload"
                :on-success="
                  (response, file) => {
                    upSuccess(response, file);
                  }
                "
                :on-error="
                  (error, file) => {
                    upError(error, file);
                  }
                "
                :data="{ type: '5' }"
                style="display: inline-block; margin-left: 100px"
                :limit="1"
                ref="upload"
              >
                <el-button>选择上传文件</el-button>
              </el-upload>
              <div
                style="margin-left: 30px"
                v-if="auditForm.auditStatus * 1 == 2"
              >
                <el-button @click="importTemplate" type="text"
                  >下载审核结果模版</el-button
                >
              </div>
              <div v-else style="color: #06b7ae; margin-left: 20px">
                （请下载商品文件，修改后导入）
              </div>
            </div>
          </el-form-item>
          <el-form-item label="原因">
            <el-input
              clearable
              type="textarea"
              placeholder="请输入原因"
              v-model="auditForm.rejectReason"
              :autosize="{ minRows: 8, maxRows: 10 }"
              maxlength="100"
              show-word-limit
              style="width: 300px; margin-left: 100px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="text-right">
              <el-button
                type="primary"
                @click="handleSubmit()"
                :loading="loading"
                >确 定</el-button
              >
              <el-button @click="handleResetForm()">重 置</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import { getToken } from "@/utils/auth";
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const { mapState, mapActions } = createNamespacedHelpers("commonIndex"); //vuex公共库
import {
  getGoodsImportRecordList, //商品文件审核列表
} from "@/api/apiAll/phpUrl.js";
export default {
  data() {
    var fileUp = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请上传文件"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      Updata: {
        supplierId: "", //供应商id
        addCourseForm: 1,
        creatorId: window.sessionStorage.getItem("admin_id"),
      }, //代供应商导入上传数据
      Actions: wholeUrl + "/basis/upload",
      token: { token: getToken() },
      auditDrawer: false,
      tableLoading: false,
      total: 0,
      current_page: null,
      queryParams: {
        page: 1,
        perPage: 10,
        fileType: "", //类型
        auditStatus: "", //审核状态
        businessName: "", //供应商名称

        dateTime: [],
        startTime: "",
        endTime: "",

        auditdateTime: [],
        auditStartTime: "",
        auditEndTIme: "",
      },
      auditForm: {
        id: "",
        auditStatus: null,
        isUpdateFile: null,
        rejectReason: "",
        updateFile: "",
      },
      gysInfo: {
        businessName: "",
        id: "",
        operatorName: "",
        createTime: "",
        importFileUrl: "",
      },
      searchListData: [],
      rules: {
        auditStatus: [
          { required: true, message: "审核结果必填", trigger: "change" },
        ],
        isUpdateFile: [
          {
            required: true,
            message: "是否修改过上传文件必填",
            trigger: "change",
          },
        ],
        updateFile: [{ validator: fileUp, trigger: "change" }],
      },
    };
  },
  computed: {
    ...commonIndex.mapState(["mainHright"]),
    ...merchantCenterList.mapState(["page"]),
  },
  created() {
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getSearchList();
  },
  filters: {
    fileState(val) {
      switch (val) {
        case 0:
          return "新增商品";
        case 1:
          return "更新商品";
        case 2:
          return "更新图片";
        case 3:
          return "更新说明书";
      }
    },
  },
  methods: {
    ...commonIndex.mapActions([
      // "getGoodsImportRecordList", //商品资料库-商品导入记录
      "postGoodsImportAudit",
    ]),
    async handleSubmit() {
      this.$refs.auditForm.validate(async (valid) => {
        if (valid) {
          let res = await this.postGoodsImportAudit(this.auditForm);
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.handleCurrentChange();
            this.handleClose();
          }
          console.log(res, "222222222");
        } else {
          console.log(valid, "error submit!!");
          return false;
        }
      });
    },
    handleResetForm() {
      this.$refs.auditForm.resetFields();
      this.auditForm.auditStatus = null;
      this.auditForm.isUpdateFile = null;
      this.auditForm.rejectReason = "";
      this.auditForm.updateFile = "";
    },
    handleDownFile(url) {
      window.open(url);
    },
    /**  */
    handleAudit(row) {
      this.gysInfo = Object.assign(this.gysInfo, row);
      this.auditForm.id = row.id;
      this.auditDrawer = true;
    },
    importTemplate() {
      let params = {
        id: this.gysInfo.id,
      };
      downLoadZip(
        `/goods/platformGoods/downloadGoodsAuditResultTemplate`,
        "xlsx",
        "审核结果模板",
        params,
        "php",
        "get"
      );
    },
    handleClose() {
      this.auditDrawer = false;
      this.handleResetForm();
    },
    //导入接口失败
    upError(error) {
      this.uploading.close();
    },
    upSuccess(response) {
      if (response.code == 200) {
        this.$message.success("上传成功");
        this.uploading.close();
        this.auditForm.updateFile = response.data.full_url;
      }
    },
    //上传前
    handleBeforeUpload(file) {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isXls = file.type === "application/vnd.ms-excel";
      //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
      const is10M = file.size / 1024 / 1024 < 10;
      //限制文件上传类型
      if (!isXlsx && !isXls) {
        this.$message.error(
          "导入失败，文件格式不正确，请确认文件格式为.xls或.xlsx。"
        );
        return false;
      }
      //限制文件上传大小
      if (!is10M) {
        this.$message.error("导入失败，文件大小不能超过10MB。");
        return false;
      }
      this.uploading = this.$loading({
        lock: true,
        text: "导入中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return true;
    },
    handleReset() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getSearchList();
    },
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    async getSearchList() {
      this.tableLoading = true;
      let data = { ...this.queryParams };
      if (this.queryParams.dateTime && this.queryParams.dateTime.length > 0) {
        data.startTime = this.queryParams.dateTime[0];
        data.endTime = this.queryParams.dateTime[1];
      } else {
        data.startTime = "";
        data.endTime = "";
      }
      if (
        this.queryParams.auditdateTime &&
        this.queryParams.auditdateTime.length > 0
      ) {
        data.auditStartTime = this.queryParams.auditdateTime[0];
        data.auditEndTIme = this.queryParams.auditdateTime[1];
      } else {
        data.auditStartTime = "";
        data.auditEndTIme = "";
      }
      let res = await getGoodsImportRecordList(data);
      this.searchListData = res.data.data;
      this.current_page = res.data.currentPage;
      this.total = res.data.total;
      this.tableLoading = false;
      this.setquery();
    },
    //当前页
    searchBusinessList(e) {
      if (e != undefined) {
        this.queryParams.page = e;
      }
      this.getSearchList();
    },
    //搜索
    handleCurrentChange() {
      this.queryParams.page = 1;
      this.getSearchList();
    },
    /** 立即审核 */
    ReviewImmediate(row) {
      if (row.fileType == 0 || row.fileType == 1) {
        this.$router.push({
          path: "/goodsImportDetail",
          query: {
            id: row.id,
          },
        });
      } else {
        this.$router.push({
          path: "/ImagInstructDetail",
          query: {
            id: row.id,
            fileType: row.fileType,
          },
        });
      }
    },
    /** 明细 */
    detailed(row) {
      this.$router.push({
        path: "/auditDetails",
        query: {
          id: row.id,
          fileType: row.fileType,
        },
      });
    },
    auditStyle(val) {
      switch (val) {
        case 0:
          return "color: #F59A23"; //无需审核
        case 1:
          return "color: #F59A23"; //待审核
        case 2:
          return "color: #3DBB2B"; //审核通过
        case 3:
          return "color: #F34444"; //审核驳回
        case 4:
          return "color: #06b7ae"; //部分通过
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-date-editor .el-range-separator {
  padding: 0 5px;
  line-height: 32px;
  width: 20%;
  color: #303133;
}
</style>
<style lang="scss" scoped>
.app-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  .merch-table {
    margin-top: 1%;
    width: 100%;
    height: 77%;
  }
  .userBtn {
    display: flex;
    justify-content: center;
    div {
      margin-left: 5px;
      color: #1abca9;
      cursor: pointer;
    }
  }
  .text_btn {
    cursor: pointer;
    color: #06b7ae;
  }
  .gysFileInfo {
    padding: 0px 100px;
    .gys-row {
      display: flex;
      div {
        flex: 1;
        margin-bottom: 20px;
        font-size: 14px;
        color: #606266;
      }
      .text_btn {
        cursor: pointer;
        color: #06b7ae;
      }
    }
  }
  .line-fen {
    content: "";
    border-top: 1px solid #606266;
    margin-bottom: 20px;
  }
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 2%;
  margin-top: 1%;
}
::v-deep .el-form-item .el-form-item__error {
  margin-left: 100px;
}
</style>
